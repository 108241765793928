/* eslint-disable no-unused-vars */
import apiClient from '@/services/apiClient';
import apiConfig from '@/services/apiConfig';
import swal from '@/common/alerts';
import { obterTipoDocumento } from '@/common/document';

export const getClienteDefaultState = () => {
    return {
        dadosCadastro: {
            Id: 0,
            Documento: '',
            Nome: '',
            InscricaoEstadual: '',
            Telefone: '',
            Celular: '',
            Email: '',
            DataNascimento: null,
            Sexo: '',
            TipoCliente: 'C',
            Endereco: {
                CEP: '',
                Logradouro: '',
                Numero: '',
                Complemento: '',
                Bairro: '',
                Cidade: '',
                UF: ''
            },
            TipoPessoa: '',
            Observacoes: '',
            EnviarParaOutroEndereco: false
        },
        tipoMensagem: { Sucesso: 1, Erro: 2, Aviso: 3 },
        ultimoDocumentoPesquisado: ''
    };
};

const state = getClienteDefaultState();

const mutations = {};

const getters = {
    dadosCadastro: () => {
        return state.dadosCadastro;
    },

    labelCampoNome: () => {
        if (actions.ehPessoaFisica()) return 'Nome';

        if (actions.ehPessoaJuridica()) return 'Razão social';

        return 'Nome / Razão social';
    },

    descricaoTipoCliente: () => {
        switch (state.dadosCadastro.TipoCliente) {
            case 'C':
                return 'Cliente';
            case 'F':
                return 'Fornecedor';
            case 'T':
                return 'Transportador';
            case 'A':
                return 'Cliente/Fornecedor';
            default:
                return 'Cliente';
        }
    },

    ehPessoaJuridica: () => actions.ehPessoaJuridica,
    ehPessoaFisica: () => actions.ehPessoaFisica
};

const actions = {
    buscarPessoaPorDocumento: ({ commit, dispatch }, documento) => {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlCrmApi() + '/api/CadastroRapido/pessoa/get/documento/' + documento;

            state.ultimoDocumentoPesquisado = documento;

            apiClient
                .get(urlRequest)
                .then((response) => {
                    dispatch('selecionarPessoa', response.data);
                    resolve();
                })
                .catch((err) => {
                    dispatch('selecionarPessoa', null);
                    console.log(err);
                    swal.exibirMensagemErro(err.response.data);
                    reject(err);
                });
        });
    },

    CrmAutocompletar: ({ commit, dispatch }, payload) => {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlCrmApi() + '/api/CrmAutocompletar/Pesquisar?' + payload;

            apiClient
                .get(urlRequest)
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    swal.exibirMensagemErro(err.response.data);
                    reject(err);
                });
        });
    },

    ehPessoaFisica: function() {
        if (!state.dadosCadastro.Documento) return false;
        return obterTipoDocumento(state.dadosCadastro.Documento) === 'CPF';
    },

    ehPessoaJuridica: function() {
        if (!state.dadosCadastro.Documento) return false;
        return obterTipoDocumento(state.dadosCadastro.Documento) === 'CNPJ';
    },

    ehDocumentoValido: function() {
        return actions.ehPessoaFisica() || actions.ehPessoaJuridica();
    },

    btnSair_Click: function() {
        if (state.limparCampos) actions.resetarInformacoesPessoa();

        state.ultimoDocumentoPesquisado = '';

        this.$emit('input', { salvou: false, dados: null });
    },

    buscarPessoaPorId: function({ commit, dispatch }, idPessoa) {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlCrmApi() + '/api/CadastroRapido/pessoa/get/id/' + idPessoa;

            apiClient
                .get(urlRequest)
                .then((response) => {
                    dispatch('selecionarPessoa', response.data);
                    resolve();
                })
                .catch((err) => {
                    dispatch('selecionarPessoa', null);
                    console.log(err);
                    swal.exibirMensagemErro(err.response.data);
                    reject(err);
                });
        });
    },

    resetarInformacoesPessoa: function() {
        state.dadosCadastro.Id = 0;
        state.dadosCadastro.Documento = '';
        state.dadosCadastro.Nome = '';
        state.dadosCadastro.InscricaoEstadual = '';
        state.dadosCadastro.Telefone = '';
        state.dadosCadastro.Celular = '';
        state.dadosCadastro.Email = '';
        state.dadosCadastro.DataNascimento = null;
        state.dadosCadastro.Sexo = '';
        state.dadosCadastro.Endereco.CEP = '';
        state.dadosCadastro.Endereco.Logradouro = '';
        state.dadosCadastro.Endereco.Numero = '';
        state.dadosCadastro.Endereco.Complemento = '';
        state.dadosCadastro.Endereco.Bairro = '';
        state.dadosCadastro.Endereco.Cidade = '';
        state.dadosCadastro.Endereco.UF = '';

        if (state.tipoCrm) state.dadosCadastro.TipoCliente = state.tipoCrm;
        else state.dadosCadastro.TipoCliente = 'C';

        state.dadosCadastro.Observacoes = '';
    },

    sair: function() {
        Object.assign(state, getClienteDefaultState());
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
