/* eslint-disable no-unused-vars */
import apiClient from '@/services/apiClient';
import apiConfig from '@/services/apiConfig';

const balanco_actions = {
  // async resetBalancos({ commit }) {
  //     commit(balanco_RESET_balancoS, null);
  // },
  atualizaFiltros({ commit }, payload) {
    commit('atualizaFiltros', payload);
  },
  atualizaBalancoSelecionado({ commit }, payload) {
    commit('atualizaBalancoSelecionado', payload);
  },
  atualizaSecaoSelecionada({ commit }, payload) {
    commit('atualizaSecaoSelecionada', payload);
  },
  async obterBalancos({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const urlRequest =
        apiConfig.getUrlSuprimentosApi() + '/api/Balanco/FiltrarBalancos';
      apiClient
        .post(urlRequest, payload)
        .then((response) => {
          commit('setFiltrarBalancos', response.data);
          return resolve(response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  async listarSecoesBalancoParaExecucao(_, payload) {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify(payload);
      const url =
        apiConfig.getUrlSuprimentosApi() +
        '/api/Balanco/ListarSecoesBalancoParaExecucao';
      apiClient
        .post(url, body)
        .then(({ data }) => resolve(data))
        .catch((erro) => reject(erro));
    });
  },
  iniciarExecucaoSecaoBalanco(_, payload) {
    return new Promise((resolve, reject) => {
      const url =
        apiConfig.getUrlSuprimentosApi() +
        '/api/Balanco/IniciarExecucaoSecaoBalanco';
      const body = JSON.stringify(payload);

      apiClient
        .post(url, body)
        .then(({ data }) => resolve(data))
        .catch((erro) => reject(erro));
    });
  },
  finalizarExecucaoSecao({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const url =
        apiConfig.getUrlSuprimentosApi() +
        '/api/Balanco/FinalizarExecucaoSecaoBalanco';
      const body = JSON.stringify(payload);

      apiClient
        .post(url, body)
        .then(({ data }) => {
          const { status } = data;
          commit('setFinalizarSecoesResponse', status);
          return resolve(data);
        })
        .catch((erro) => reject(erro));
    });
  },
  async validarCodigoDeBarras({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const url =
        apiConfig.getUrlSuprimentosApi() + '/api/Balanco/ValidarCodigoDeBarras';
      const body = JSON.stringify(payload);
      apiClient
        .post(url, body)
        .then(({ data }) => {
          commit('setProdutoPesquisado', data);
          return resolve(data);
        })
        .catch((erro) => reject(erro));
    });
  },
  async conferirExecucaoItemSecao(_, payload) {
    return new Promise((resolve, reject) => {
      const url =
        apiConfig.getUrlSuprimentosApi() +
        '/api/Balanco/ConferirExecucaoItemSecao';
      const body = JSON.stringify(payload);

      apiClient
        .post(url, body)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((erro) => reject(erro));
    });
  },
  excluirConferenciaExecucaoItemSecao(_, payload) {
    return new Promise((resolve, reject) => {
      const url =
        apiConfig.getUrlSuprimentosApi() +
        '/api/Balanco/excluirConferenciaExecucaoItemSecao';
      const body = JSON.stringify(payload);

      apiClient
        .post(url, body)
        .then(({ data }) => resolve(data))
        .catch((erro) => reject(erro));
    });
  },
  atualizarListaItensConferidos({ commit }, payload) {
    commit('atualizarListaItensConferidos', payload);
  },
};

export default balanco_actions;
