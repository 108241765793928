const requisicao_wms_getters = {
    requisicoes(state) {
        return state.requisicoes;
    },
    vendedores(state) {
        return state.vendedores;
    },
    tiposRequisicao(state) {
        return state.tiposRequisicao;
    },
    etapasProcesso(state) {
        return state.etapasProcesso;
    },
    naturezasOperacao(state) {
        return state.naturezasOperacao;
    },
    permissoesWMS(state) {
        return state.permissoesWMS;
    },
    statusRequisicao(state) {
        return state.statusRequisicao;
    },
    tipoSolicitacao(state) {
        return state.tipoSolicitacao;
    },
    tiposOperacao(state) {
        return state.tiposOperacao;
    },
    filtrosRequisicoes(state) {
        return state.filtrosRequisicoes;
    },
    consultaRequisicao(state) {
        return state.consultaRequisicao;
    }
};

export default requisicao_wms_getters;
