import {
    isMobile,
    isTablet,
    isBrowser,
    isAndroid,
    isIOS,
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isIE,
    isEdge,
    isChromium,
    isMobileSafari,
    osVersion,
    osName,
    browserVersion,
    browserName,
    mobileVendor,
    mobileModel,
    deviceType,
    getUA
} from 'mobile-device-detect';

const getUiStateDefaultState = () => {
    return {
        tituloPagina: '',
        menuAberto: false,
        isLoading: false,
        exibirPreferencias: true,
        hasVideoDevice: true
    };
};

const state = getUiStateDefaultState();

const mutations = {
    toggleMenu(state) {
        state.menuAberto = !state.menuAberto;
    },

    updateNavbar(state, payload) {
        state.tituloPagina = payload;
    },

    showLoading(state) {
        state.isLoading = true;
    },

    hideLoading(state) {
        state.isLoading = false;
    },

    reset(state) {
        const defaultState = getUiStateDefaultState();

        Object.assign(state, {
            ...defaultState
        });
    },
    updateVideoDevice(state, payload) {
        state.hasVideoDevice = payload;
    }
};

const actions = {
    updateNavbar({ commit }, payload) {
        commit('updateNavbar', payload);
    },
    toggleMenu({ commit }) {
        commit('toggleMenu');
    },
    showLoading({ commit }) {
        commit('showLoading');
    },
    hideLoading({ commit }) {
        commit('hideLoading');
    },
    updateVideoDevice({ commit }, payload) {
        commit('updateVideoDevice', payload);
    }
};

const getters = {
    device: () => {
        const isEgdeChromium = isChrome && getUA.indexOf(' Edg/') >= 0;

        return {
            isMobile,
            isAndroid,
            isIOS,
            isTablet,
            mobileVendor,
            mobileModel,
            browserName: isEgdeChromium ? 'Edge (Chromium)' : browserName,
            browserVersion,
            isChrome,
            isFirefox,
            isSafari,
            isIE,
            isEdge,
            isEgdeChromium,
            isOpera,
            isMobileSafari,
            isChromium,
            osName,
            osVersion,
            deviceType,
            isBrowser,
            getUA
        };
    },
    hasVideoDevice(state) {
        return state.hasVideoDevice;
    },
    tituloPagina(state) {
        return state.tituloPagina;
    },
    menuAberto(state) {
        return state.menuAberto;
    },
    isLoading(state) {
        return state.isLoading;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
