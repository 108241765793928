import Vue from 'vue';
import loading from '@/common/loading';

function exibirMensagem() {
    this.tipoMensagem = { Sucesso: 1, Erro: 2, Atencao: 3 };
}

var _obterMensagemLista = function (listaMensagem) {
    var mensagem = '';

    if (Array.isArray(listaMensagem)) {
        mensagem = listaMensagem.join('</br>');
    } else {
        mensagem = listaMensagem.toString();
    }

    return mensagem;
};

exibirMensagem.prototype.exibirPergunta = function (titulo, mensagem, funcCallback) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        if (!funcCallback) funcCallback = function () {};

        Vue.swal({
            title: titulo,
            html: mensagem,
            showCloseButton: true,
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-primary mr-1 rounded',
                cancelButton: 'btn btn-secondary rounded'
            },
            buttonsStyling: false,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(function (result) {
            if (result.value === true) funcCallback(true);
            else if (result.dismiss === 'cancel') funcCallback(false);

            resolve(result.value);
        });
    });
};

exibirMensagem.prototype.exibirPerguntaFinalizacaoEtapa = function (titulo, mensagem,tituloCheckbox, funcCallback) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
        if (!funcCallback) funcCallback = function () {};

        Vue.swal({
            input: 'checkbox',
            inputValue: 1,
            inputPlaceholder: tituloCheckbox,
            title: titulo,
            html: mensagem,
            showCloseButton: true,
            showCancelButton: true,
            customClass: {
                confirmButton: 'btn btn-primary mr-1 rounded',
                cancelButton: 'btn btn-secondary rounded'   
            },
            buttonsStyling: false,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(function (result) {
            if (result.isConfirmed === true) funcCallback(result);
            else if (result.dismiss === 'cancel') funcCallback(false);

            resolve(result.value);
        });
    });
};

exibirMensagem.prototype.exibirMensagemSucesso = function (mensagem, funcaoSucesso, mostraToast) {
    this.exibirMensagem(mensagem, this.tipoMensagem.Sucesso, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirToastSucesso = function (mensagem, callback) {
    this.exibirMensagem(mensagem, this.tipoMensagem.Sucesso, callback, true);
};

exibirMensagem.prototype.exibirToastAlerta = function (mensagem, callback) {
    this.exibirMensagem(mensagem, this.tipoMensagem.Atencao, callback, true);
};

exibirMensagem.prototype.exibirMensagemErro = function (mensagem, funcaoSucesso, mostraToast) {
    this.exibirMensagem(mensagem, this.tipoMensagem.Erro, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirListaMensagemErro = function (listaMensagem, funcaoSucesso, mostraToast) {
    var mensagem = _obterMensagemLista(listaMensagem);

    this.exibirMensagem(mensagem, this.tipoMensagem.Erro, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirListaMensagemAlerta = function (listaMensagem, funcaoSucesso, mostraToast) {
    var mensagem = _obterMensagemLista(listaMensagem);

    this.exibirMensagem(mensagem, this.tipoMensagem.Atencao, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirListagemMensagemAlerta = function (mensagem, funcaoSucesso, mostraToast) {
    this.exibirMensagem(mensagem, this.tipoMensagem.Atencao, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirMensagemAlerta = function (mensagem, funcaoSucesso, mostraToast) {
    this.exibirMensagem(mensagem, this.tipoMensagem.Atencao, funcaoSucesso, mostraToast);
};

exibirMensagem.prototype.exibirMensagem = function (mensagem, tipoMensagem, funcaoSucesso, mostraToast, tituloCustomizado) {
    let tipo = '';
    let titulo = '';

    let propsSwal = {
        html: mensagem,
        confirmButtonText: 'Fechar',
        customClass: {
            confirmButton: 'btn btn-primary mr-1 rounded',
            cancelButton: 'btn btn-secondary rounded'
        },
        buttonsStyling: false
    };

    if (mostraToast === undefined) mostraToast = false;

    if (tipoMensagem === this.tipoMensagem.Sucesso) {
        tipo = 'success';
        titulo = 'Legal!!';
    } else if (tipoMensagem === this.tipoMensagem.Erro) {
        tipo = 'error';
        titulo = 'Oops!!';
    } else if (tipoMensagem === this.tipoMensagem.Atencao) {
        tipo = 'warning';
        titulo = 'Atenção';
    }

    if (mostraToast) {
        propsSwal.showCancelButton = false;
        propsSwal.showConfirmButton = false;
        propsSwal.toast = true;
        propsSwal.position = 'top';
        propsSwal.timer = 3000;
        propsSwal.width = '95%';
    } else {
        propsSwal.allowOutsideClick = false;
    }

    propsSwal.icon = tipo;
    propsSwal.title = tituloCustomizado || titulo;

    Vue.swal(propsSwal).then(function (result) {
        if (funcaoSucesso) {
            funcaoSucesso(result);
        }
    });
};

exibirMensagem.prototype.exibirMensagemValidacaoApi = function (retorno) {
    loading.ocultar();
    Vue.swal({
        icon: 'warning',
        title: 'Atenção',
        html: retorno.join('<br />'),
        confirmButtonText: 'Fechar',
        customClass: {
            confirmButton: 'btn btn-primary mr-1 rounded',
            cancelButton: 'btn btn-secondary rounded'
        },
        allowOutsideClick: false,
        buttonsStyling: false
    });
};
// eslint-disable-next-line no-unused-vars
exibirMensagem.prototype.exibirMensagemErroApi = function (status, message, stack) {
    loading.ocultar();
    Vue.swal({
        icon: 'error',
        title: 'Atenção',
        html: 'Aconteceu algo inesperado.' + message,
        confirmButtonText: 'Fechar',
        customClass: {
            confirmButton: 'btn btn-primary mr-1 rounded',
            cancelButton: 'btn btn-secondary rounded'
        },
        allowOutsideClick: false,
        buttonsStyling: false
    });
};

export default new exibirMensagem();
