export const NOMES_PRODUTOS_API = [
    'LMX.ES.WebApi',
    'LMX.API.NFe 4.0',
    'LMX.CRM.WebApi',
    'LMX.Fiscal.WebApi',
    'LMX.Faturamento.WebApi',
    'LMX.AdmFinanceiro.WebApi',
    'LinxMicrovix.WebApi.LIS',
    'LMX.Suprimentos.WebApi',
    'LMX.Produtos.Imagens.WebApi'
];

export const AUTENTICACAO_TOKEN = 'AUTENTICACAO_TOKEN';
export const AUTENTICACAO_WMS = 'AUTENTICACAO_WMS';
export const AUTENTICACAO_ANONIMA = 'AUTENTICACAO_ANONIMA';
