import { getRoute } from './utils';

//eslint-disable-next-line no-unused-vars

export default (router, store) => {
    const updateTitleNavBar = (to, from, next) => {
        if (!to) return;

        let title = null;
        let config = null;

        const currentRoute = getRoute(router.options.routes, to.name);
        if (currentRoute && currentRoute.meta) {
            title = currentRoute.meta.title;

            if (currentRoute.meta.config) {
                config = currentRoute.meta.config(store);
                title = config.title;
            }
        }

        if (!title) {
            title = to.name;
        }

        store.dispatch('uiState/updateNavbar', title);

        return next();
    };

    // validation logged user
    router.beforeEach((to, from, next) => {
        const loggedIn = store.getters['auth/usuarioLogado'];
        if (to.meta.requiresAuth && !loggedIn) {
            next({
                name: 'login'
            });
        } else {
            updateTitleNavBar(to, from, next);
        }
    });
};
