// import apiClient from "../../services/apiClient";
// import apiConfig from "../../services/apiConfig";
import { AUTENTICACAO_WMS, AUTENTICACAO_TOKEN } from '../modules/auth/auth-mutations-types';
import '@/common/extensions/array.extension';

const state = () => ({
  permissoes: {},
  urlBaseERP: null,
});

const mutations = {
  atualizarPermissoesUsuarios(state, payload) {
    if (!payload) {
      return;
    }
    state.permissoes = payload;
  },

  atualizarDadosEmpresaPortal(state, payload) {
    const urlBaseEnv = process.env.VUE_APP_URL_ERP;
    if (urlBaseEnv) state.urlBaseERP = urlBaseEnv;
    else state.urlBaseERP = payload.EnderecoServidorScript;
  },

  atualizarPermissaoUsuarioWMS(state, payload) {
    state.permissoes = payload;
  },
};

const actions = {};

const getters = {
  ehWMSMobile: (state, getters, rootState, rootGetters) => {
    const device = rootGetters['uiState/device'];
    return device && (device.isMobile || device.isTablet);
  },

  ehWMSStandalone: (state, getters, rootState) => {
    const vfMobile = getters['ehWMSMobile'];
    return vfMobile || rootState.autenticacao.metodoAutenticacao.tipo === AUTENTICACAO_WMS;
  },

  urlGuiaRapidoWMS: () => {
    return 'https://share.linx.com.br/x/5Gi7Cw';
  },

  possivelRedirecionarParaErp(rootState) {
    return rootState?.auth?.metodoAutenticacao?.tipo === AUTENTICACAO_TOKEN;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
