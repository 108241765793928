import balanco_actions from './balanco-actions';
import balanco_getters from './balanco-getters';
import balanco_mutations from './balanco-mutations';

const balanco_module = {
  namespaced: true,
  state: {
    filtros: {
      idEmpresa: null,
      dataInicial: null,
      dataFinal: null,
    },
    balancos: [],
    balancoSelecionado: {
      IdBalanco: 0,
      DataLancamento: '',
      DataLancamentoFormat: '',
      Descricao: '',
      IdEmpresa: '',
      IdStatusBalanco: '',
      QtdeSecao: '',
      Secao: '',
    },
    listaSecoesBalancoParaExecucao: [],
    secaoSelecionada: null,
    produto: {},
    responseFinalizarSecoes: {},
    responseFiltrarBalancos: {},
    listaItensConferidos: [],
  },
  getters: balanco_getters,
  actions: balanco_actions,
  mutations: balanco_mutations,
};

export default balanco_module;
