const getOnlyNumbers = (txt) => {
    if (!txt) {
        return '';
    }
    return txt.replace(/\D/g, '');
};

const trim = (str) => {
    return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
};

const replaceAll = (text, searchFor, replaceWith) => {
    return text.replace(new RegExp(searchFor, 'g'), replaceWith);
};

export default {
    getOnlyNumbers,
    trim,
    replaceAll
};
