const auth_mutations = {
    login: (state, payload) => {
        state.usuario = payload.usuario;
        state.tokenTemporarioLogin = payload.tokenTemporario;
        state.LmxAuthToken = payload.tokenTemporario;
        state.portaisUsuario = payload.portais;
        state.autenticado = true;
    },

    selecionarEmpresa: (state, payload) => {
        state.empresa = payload;
        state.autenticado = true;
    },

    logout: (state) => {
        state.usuario = null;
        state.empresa = null;
        state.autenticado = false;
        state.tokenTemporarioLogin = null;
        state.portaisUsuario = null;
        state.tipoAutenticacao = null;
        state.metodoAutenticacao.tipo = null;
        state.metodoAutenticacao.nomeAplicacaoOrigem = null;
    },

    atualizarMetodoAutenticacao: (state, payload) => {
        state.metodoAutenticacao.tipo = payload.tipo;
        state.metodoAutenticacao.nomeAplicacaoOrigem = payload.nomeAplicacaoOrigem;
    },

    setarAutenticado: (state) => {
        state.autenticado = true;
    }
};

export default auth_mutations;
