/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
    const verificarSePaginaEhPublica = function () {
        return document.location.href.indexOf('/publico/') >= 0;
    };

    const exibirLoadingUpdateSW = function () {
        if (verificarSePaginaEhPublica()) return;

        const divLoadSw = document.createElement('div');
        divLoadSw.id = 'index-splash-screen-loading-overlay';
        divLoadSw.innerHTML = `
      <div id="index-splash-screen-loading-title-wms" style="top: 40%; left: 50%; transform: translate(-50%, -40%); position: relative;">Aguarde só mais uns instantes, estamos atualizando a sua versão</div>
      <div id="index-splash-screen-loading-center-spinner">
        <div id="index-splash-screen-spinner">
          <div id="index-splash-screen-double-bounce1"></div>
          <div id="index-splash-screen-double-bounce2"></div>
        </div>
      </div>
      <div id="index-splash-screen-loading-title-container">
        <div id="index-splash-screen-loading-title-linxmicrovix">Linx Microvix</div>
        <div id="index-splash-screen-loading-title-wms">WMS</div>
      </div> `;

        document.body.appendChild(divLoadSw);
    };

    const atualizaAppAposAtualizarSW = function () {
        if (verificarSePaginaEhPublica()) return;

        const divLoadSw = document.getElementById('index-splash-screen-loading-overlay');
        if (divLoadSw) {
            window.location.reload(true);
        }
    };

    //register(`${process.env.BASE_URL}sw.js`, {
    register(`${process.env.BASE_URL}sw.js`, {
        ready() {
            console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB');
        },
        registered() {
            console.log('Service worker has been registered.');
            atualizaAppAposAtualizarSW();
        },
        cached() {
            console.log('Content has been cached for offline use.');
            atualizaAppAposAtualizarSW();
        },
        updatefound() {
            console.log('New content is downloading.');
            exibirLoadingUpdateSW();
        },
        updated() {
            console.log('New content is available; please refresh.');
            window.location.reload(true);
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:' + error, error);
        }
    });
}
