import { PRODUTO_ATUALIZA_PRODUTOS, PRODUTO_RESET_PRODUTOS } from './produto-mutations-types';

const produto_mutations = {
    [PRODUTO_ATUALIZA_PRODUTOS](state, produtos) {
        state.produtos = produtos;
    },
    [PRODUTO_RESET_PRODUTOS](state) {
        state.produtos = [];
    }
};

export default produto_mutations;
