/* eslint-disable no-unused-vars */
import apiClient from '@/services/apiClient';
import apiConfig from '@/services/apiConfig';

import {
    REQUISICAO_ATUALIZA_VENDEDORES,
    REQUISICAO_ATUALIZA_TIPOS_REQUISICAO,
    REQUISICAO_ATUALIZA_ETAPAS_PROCESSO,
    REQUISICAO_ATUALIZA_NATUREZAS_OPERACAO,
    REQUISICAO_ATUALIZA_PERMISSOES_WMS,
    REQUISICAO_ATUALIZA_STATUS_REQUISICAO,
    REQUISICAO_ATUALIZA_TIPO_SOLICITACAO,
    REQUISICAO_ATUALIZA_TIPOS_OPERACAO,
    REQUISICAO_ATUALIZA_REQUISICOES_WMS,
    REQUISICAO_ATUALIZA_FILTROS_REQUISICOES_WMS,
    REQUISICAO_CONSULTA_REQUISICAO_WMS
} from './requisicao-wms-mutations-types';

const requisicao_wms_actions = {
    async obterDadosIniciaisPainelMovimentacoes({ commit }) {
        const urlRequest = apiConfig.getUrlSuprimentosApi() + '/api/RequisicoesWMS/ObterFiltrosListagemPainelMovimentacoes';

        const resp = await apiClient
            .get(urlRequest)
            .then((response) => {
                commit(REQUISICAO_ATUALIZA_VENDEDORES, response.data.Vendedores);
                commit(REQUISICAO_ATUALIZA_TIPOS_REQUISICAO, response.data.TiposRequisicao);
                commit(REQUISICAO_ATUALIZA_ETAPAS_PROCESSO, response.data.EtapasProcessoRequisicao);
                commit(REQUISICAO_ATUALIZA_NATUREZAS_OPERACAO, response.data.NaturezasOperacao);
                commit(REQUISICAO_ATUALIZA_PERMISSOES_WMS, response.data.PermissoesWMS);
                commit(REQUISICAO_ATUALIZA_STATUS_REQUISICAO, response.data.Status);
                commit(REQUISICAO_ATUALIZA_TIPO_SOLICITACAO, response.data.TipoSolicitacao);
                commit(REQUISICAO_ATUALIZA_TIPOS_OPERACAO, response.data.TiposOperacao);
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            });

        return resp;
    },
    async obterListaRequisicoesWMS({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlSuprimentosApi() + '/api/RequisicoesWMS/ListarRequisicoesWMS';
            apiClient
                .post(urlRequest, payload)
                .then((response) => {
                    commit(REQUISICAO_ATUALIZA_REQUISICOES_WMS, response.data);
                    return resolve(response);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    },
    atualizarFiltrosRequisicoes({ commit }, payload) {
        commit(REQUISICAO_ATUALIZA_FILTROS_REQUISICOES_WMS, payload);
    },

    async obterItensRequisicao({ commit }, payload) {
        const urlRequest = apiConfig.getUrlSuprimentosApi() + '/api/RequisicoesWMS/ListarItensConferencia';
        return await apiClient.post(urlRequest, payload).then((resp) => resp.data);
    },

    async conferirItem({ commit }, payload) {
        const urlRequest = apiConfig.getUrlSuprimentosApi() + '/api/RequisicoesWMS/ConferirItem';
        return await apiClient.post(urlRequest, payload).then((resp) => resp.data);
    },
    async limparConferidos({ commit }, payload) {
        const urlRequest = apiConfig.getUrlSuprimentosApi() + '/api/RequisicoesWMS/LimparItensConferidos';
        return await apiClient.post(urlRequest, payload).then((resp) => resp.data);
    },
    async finalizarConferencia({ commit }, payload) {
        const urlRequest = apiConfig.getUrlSuprimentosApi() + '/api/RequisicoesWMS/FinalizarEtapaConferencia';
        return await apiClient.post(urlRequest, payload).then((resp) => resp.data);
    },
    async alterarAcoesRequisicoesWMS({ commit }, payload) {
        const urlRequest = apiConfig.getUrlSuprimentosApi() + '/api/RequisicoesWMS/AlterarAcoesRequisicoesWMS';
        return await apiClient.post(urlRequest, payload).then((resp) => resp.data);
    },
    async consultaRequisicaoWMS({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const urlRequest = apiConfig.getUrlSuprimentosApi() + '/api/RequisicoesWMS/ObterRequisicaoWMS';
            apiClient
                .post(urlRequest, payload)
                .then((resp) => {
                    commit(REQUISICAO_CONSULTA_REQUISICAO_WMS, resp.data);
                    return resolve(resp);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
};

export default requisicao_wms_actions;
