import stringHelper from '@/common/string';

export const obterTipoDocumento = (documento) => {
    if (!documento) return null;
    const documentoSomenteNumeros = stringHelper.getOnlyNumbers(documento);

    if (documentoSomenteNumeros.length == 11) return 'CPF';
    if (documentoSomenteNumeros.length == 14) return 'CNPJ';
    return '';
};

export const formatarDocumento = (documento) => {
    if (!documento) return null;

    const documentoSomenteNumeros = stringHelper.getOnlyNumbers(documento);

    if (obterTipoDocumento(documentoSomenteNumeros) == 'CPF') return documentoSomenteNumeros.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    else if (obterTipoDocumento(documentoSomenteNumeros) == 'CNPJ') return documentoSomenteNumeros.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1 $2 $3/$4-$5');
    else return documento;
};

export const validarCPF = (strCPF) => {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == '00000000000') return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;

    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
};

export const validarCNPJ = (cnpj) => {
    var valida = new Array(6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
    var dig1 = new Number();
    var dig2 = new Number();

    var exp = /\.||\//g;
    cnpj = cnpj.toString().replace(exp, '');
    var digito = new Number(cnpj.charAt(12) + cnpj.charAt(13));

    for (let i = 0; i < valida.length; i++) {
        dig1 += i > 0 ? cnpj.charAt(i - 1) * valida[i] : 0;
        dig2 += cnpj.charAt(i) * valida[i];
    }
    dig1 = dig1 % 11 < 2 ? 0 : 11 - (dig1 % 11);
    dig2 = dig2 % 11 < 2 ? 0 : 11 - (dig2 % 11);

    if (dig1 * 10 + dig2 != digito) return false;
    else return true;
};
