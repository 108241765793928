import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';
import GlobalGuards from './global.guards';
//import { updateTitlePageMiddleware } from "./middlewares";

Vue.use(Router);

//router.beforeEach(updateTitlePageMiddleware);

const router = new Router({
    linkExactActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/auth-pages/login')
        },
        { path: '/index.html', component: () => import(/* webpackChunkName: "login" */ '@/pages/auth-pages/login') }, // Fix for PWA at /index.html

        {
            path: '/logout',
            name: 'logout',
            component: () => import(/* webpackChunkName: "logout" */ '@/pages/auth-pages/logout')
        },
        {
            path: '/recuperacao-senha',
            name: 'recuperacao-senha',
            component: () => import(/* webpackChunkName: "recuperacao-senha" */ '@/pages/auth-pages/recuperacao-senha'),
            meta: {
                title: 'Recuperação de senha'
            }
        },
        {
            path: '/autenticacao-por-token',
            name: 'autenticacao-por-token',
            component: () => import('@/pages/auth-pages/autenticacao-por-token.vue'),
            meta: {
                title: 'Autenticação por Token'
            }
        },
        {
            path: '/app',
            name: 'layout',
            component: () => import('@/pages/layout.vue'),
            children: [
                {
                    path: '/home',
                    name: 'home',
                    component: () => import('@/pages/home.vue'),
                    meta: {
                        requiresAuth: true,
                        title: ' '
                    }
                },
                {
                    path: '/produto-pesquisa',
                    name: 'produto-pesquisa',
                    component: () => import('@/pages/produtos/produto-pesquisa.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Pesquisar Produtos'
                    }
                },
                {
                    path: '/movimentacoes',
                    name: 'movimentacoes',
                    component: () => import('@/pages/painel/movimentacoes.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Painel de Movimentações'
                    },
                    children: [
                        {
                            path: '/filtros-requisicoes',
                            name: 'filtros-requisicoes',
                            component: () => import('@/pages/painel/filtros-requisicoes.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Filtros Requisições / Operações'
                            }
                        },
                        {
                            path: '/lista-requisicoes',
                            name: 'lista-requisicoes',
                            component: () => import('@/pages/painel/lista-requisicoes.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Lista de Requisições  / Operações'
                            }
                        },
                        {
                            path: '/itens-requisicoes/:requisicoes',
                            name: 'itens-requisicoes',
                            component: () => import('@/pages/painel/itens-requisicoes.vue'),
                            meta: {
                                requiresAuth: true,
                                title: 'Itens das Requisições  / Operações'
                            },
                            props: true
                        }
                    ]
                },
                {
                    path: '/filtros-balancos',
                    name: 'filtros-balancos',
                    component: () => import('@/pages/balanco/filtros-balancos.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Balanço Fácil'
                    }
                },
                {
                    path: '/lista-balancos',
                    name: 'lista-balancos',
                    component: () => import('@/pages/balanco/lista-balancos.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Balanço Fácil'
                    }
                },
                {
                    path: '/secao-balanco/:idBalanco',
                    name: 'secao-balanco',
                    component: () => import('@/pages/balanco/secao-balanco.vue'),
                    props: true,
                    meta: {
                        requiresAuth: true,
                        title: 'Seção - Balanço Fácil'
                    }
                },
                {
                    path: '/execucao-secao',
                    name: 'execucao-secao',
                    component: () => import('@/pages/balanco/execucao-secao.vue'),
                    meta: {
                        requiresAuth: true,
                        title: 'Execução Seção - Balanço Fácil'
                    }
                },
                // {
                //     path: '/itens-balancos/:balancos',
                //     name: 'itens-balancos',
                //     component: () => import('@/pages/painel/itens-balancos.vue'),
                //     meta: {
                //         requiresAuth: true,
                //         title: 'Balanço Fácil'
                //     },
                //     props: true
                // }
                
            ]
        },
        // Catch All
        {
            path: '*',
            component: () => import('@/pages/pagina404.vue'),
            meta: {
                title: 'Página não encontrada'
            }
        }
    ]
});

GlobalGuards(router, store);

export default router;
