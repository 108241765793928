import store from '@/store/index';
import router from '@/router/index';

export const getRoute = function(routes, routeName) {
    let result = null;

    for (let idx = 0; idx < routes.length; idx++) {
        const element = routes[idx];

        result = getChildrenRoute(routeName, element);
        if (result) return result;
    }

    return result;
};
const getChildrenRoute = function(routeName, route) {
    if (route.name === routeName) return route;

    if (!route.children) return null;

    let childResult = null;
    for (let index = 0; index < route.children.length; index++) {
        const child = route.children[index];

        if (child.name === routeName) return child;

        if (child.children) {
            childResult = getChildrenRoute(routeName, child);
            if (childResult) return childResult;
        }
    }

    return null;
};
// eslint-disable-next-line no-unused-vars
const updateTitlePageMiddleware = (to, from, next) => {
    if (!to) return;

    let title = null;
    let config = null;

    const currentRoute = getRoute(router.options.routes, to.name);
    if (currentRoute && currentRoute.meta) {
        title = currentRoute.meta.title;

        if (currentRoute.meta.config) {
            config = currentRoute.meta.config(store);
            title = config.title;
        }
    }

    if (!title) {
        title = to.name;
    }
    this.$store.dispatch('uiState/updateNavbar', title);

    return next();
};
