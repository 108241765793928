import { AUTENTICACAO_WMS, AUTENTICACAO_ANONIMA } from './auth-mutations-types';

const auth_getters = {
    autenticado: (state) => {
        return state.usuario?.Id && state.tokenTemporarioLogin;
    },
    usuarioLogado: (state) => {
        return state.usuario;
    },
    empresaSelecionada: (state) => {
        return state.empresa;
    },
    ehAutenticacaoAnonima: (state) => {
        var retorno = state.metodoAutenticacao.tipo == AUTENTICACAO_ANONIMA;
        return retorno;
    },
    ehAutenticacaoWMS: (state) => {
        return state.metodoAutenticacao.tipo === AUTENTICACAO_WMS;
    },
    portaisUsuario: (state) => {
        return state.portaisUsuario;
    },
    empresasUsuario: (state) => {
        return state.portaisUsuario[0].Empresas;
    }
};

export default auth_getters;
