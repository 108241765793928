import {
    REQUISICAO_ATUALIZA_VENDEDORES,
    REQUISICAO_ATUALIZA_TIPOS_REQUISICAO,
    REQUISICAO_ATUALIZA_ETAPAS_PROCESSO,
    REQUISICAO_ATUALIZA_NATUREZAS_OPERACAO,
    REQUISICAO_ATUALIZA_PERMISSOES_WMS,
    REQUISICAO_ATUALIZA_STATUS_REQUISICAO,
    REQUISICAO_ATUALIZA_TIPO_SOLICITACAO,
    REQUISICAO_ATUALIZA_TIPOS_OPERACAO,
    REQUISICAO_ATUALIZA_REQUISICOES_WMS,
    REQUISICAO_ATUALIZA_FILTROS_REQUISICOES_WMS,
    REQUISICAO_CONSULTA_REQUISICAO_WMS
} from './requisicao-wms-mutations-types';

const requisicao_wms_mutations = {
    [REQUISICAO_ATUALIZA_VENDEDORES](state, payload) {
        state.vendedores = payload;
    },
    [REQUISICAO_ATUALIZA_TIPOS_REQUISICAO](state, payload) {
        state.tiposRequisicao = payload;
    },
    [REQUISICAO_ATUALIZA_ETAPAS_PROCESSO](state, payload) {
        state.etapasProcesso = payload;
    },
    [REQUISICAO_ATUALIZA_NATUREZAS_OPERACAO](state, payload) {
        state.naturezasOperacao = payload;
    },
    [REQUISICAO_ATUALIZA_PERMISSOES_WMS](state, payload) {
        state.permissoesWMS = payload;
    },
    [REQUISICAO_ATUALIZA_STATUS_REQUISICAO](state, payload) {
        state.statusRequisicao = payload;
    },
    [REQUISICAO_ATUALIZA_TIPO_SOLICITACAO](state, payload) {
        state.tipoSolicitacao = payload;
    },
    [REQUISICAO_ATUALIZA_TIPOS_OPERACAO](state, payload) {
        state.tiposOperacao = payload;
    },
    [REQUISICAO_ATUALIZA_REQUISICOES_WMS](state, payload) {
        state.requisicoes = payload;
    },
    [REQUISICAO_ATUALIZA_FILTROS_REQUISICOES_WMS](state, payload) {
        state.filtrosRequisicoes = payload;
    },
    [REQUISICAO_CONSULTA_REQUISICAO_WMS](state, payload) {
        state.consultaRequisicao = payload;
    }
};

export default requisicao_wms_mutations;
